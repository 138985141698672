<template>
  <div>
    <b-sidebar
        id="sidebar-backdrop"
        bg-variant="white"
        shadow
    >
      <StockCardDetail :stockCard="selectedStock"></StockCardDetail>
    </b-sidebar>
    <StockSearch></StockSearch>
    <b-card
        no-body
        class="mb-1"
    >
      <b-table
          :items="_stocks"
          :fields="columns"
          striped
          responsive
      >
        <template #cell(id_detail)="data">
          <b-form-checkbox
              class="custom-control-success"
              :value="data.item.id" v-model="$store.state.stockV2.selected"
          />
          <b-badge>{{ data.item.id }}</b-badge>
        </template>
        <template #cell(stock_return)="data">
          <b-badge>{{ supplierName(data.item.stock_return) }}</b-badge>
          <br>
          <b-badge variant="success">{{ supplierName(data.item.supplier_id) }}</b-badge>
        </template>

        <template #cell(productImageDetail)="data">
          <ProductImages prefix="https://api.kitapfirsatlari.com/" :images="data.item.images"/>
        </template>

        <template #cell(productDetail)="data">
          <b>{{ data.item.product.name }}</b><br>
          <b>{{ data.item.product.barcode }}</b><br>
          <b>Raf: {{ data.item.shelve_name }}</b><br>
          Adet:
          <b-badge variant="success">{{ data.item.quantity }}</b-badge>
          Ad.<br>
          PSF: {{ data.item.market_price }} ₺<br>
          Alış: {{ data.item.price }} ₺<br>

          <div v-for="(orderProduct,opIndex) in data.item.order_products" :key="opIndex">
            <b-badge variant="danger"> Sipariş: {{ orderProduct.count }}</b-badge>
            <b-badge variant="danger"> Adet: {{ orderProduct.sum }}</b-badge>
          </div>
        </template>

        <template #cell(cardDetail)="data">
          <b-row v-for="(stock_card,index) in data.item.stock_card" :key="index">
            <b-col md="12" :class="stock_card.parent_id?'bg-warning':'bg-primary'">
              <b-input-group>
                <b-input type="text" :value="'Sip.No: '+stock_card.order_product_id" disabled/>
                <b-input type="text" :value="'Adet: '+stock_card.quantity" disabled/>
                <b-input type="text" :value="userName(stock_card.add_user_id)" disabled/>
                <b-input type="text" :value="stock_card.shelve_name" disabled/>
              </b-input-group>
            </b-col>
            <b-col md="12">
              <b-row v-for="item in stock_card.sub_items" :key="item.id">
                <b-col md="12" :class="item.parent_id?'bg-warning':'bg-primary'">
                  <b-input-group>
                    <b-input type="text" :value="'Sip.No: '+item.order_product_id" disabled/>
                    <b-input type="text" :value="'Adet: '+item.quantity" disabled/>
                    <b-input type="text" :value="userName(item.add_user_id)" disabled/>
                    <b-input type="text" :value="item.shelve_name" disabled/>
                  </b-input-group>
                </b-col>

              </b-row>
            </b-col>
          </b-row>
        </template>
        <template #cell(dateDetail)="data">
          <b-row>
            <b-col md="12">
              <ViewDate :date="data.item.created_at" name="Ekl."/>
              <ViewDate :date="data.item.updated_at" name="Günc."/>
              <b-button-group>
                <b-button variant="warning" @click="decrease(data.item)" title="Stok azaltma">
                  <feather-icon icon="MinusCircleIcon"></feather-icon>
                </b-button>
                <b-button variant="danger" @click="resetStock(data.item)" title="Stok sıfırlama">
                  <feather-icon icon="MinusCircleIcon"></feather-icon>
                </b-button>
              </b-button-group>
            </b-col>
          </b-row>
        </template>
      </b-table>
    </b-card>
  </div>
</template>

<script>
import StockSearch from "@/views/stock/v2/StockSearch";
import {mapGetters} from "vuex";
import ViewDate from "@/views/common/ViewDate";
import ProductImages from "@/components/ProductImages";
import StockCardDetail from "@/views/stock/v2/StockCardDetail";
import {BBadge} from "bootstrap-vue";


export default {
  name: "StockHome",
  components: {
    BBadge,
    StockSearch, ViewDate, ProductImages, StockCardDetail
  },
  data: () => ({
    selectedStock: {},
    columns: [
      {
        key: 'id_detail',
        label: 'Sıra No',
        sortable: true
      },
      {
        key: 'stock_return',
        label: 'Stok',
        sortable: true
      },
      {
        key: 'productImageDetail',
        label: 'Resim',
        sortable: false
      },

      {
        key: 'productDetail',
        label: 'Ürün Bilgisi',
        sortable: false,
        thStyle: {width: '500px'}
      },
      {
        key: 'cardDetail',
        label: 'Giriş Çıkış',
        sortable: false,
        thStyle: {width: '600px'}
      },
      {
        key: 'dateDetail',
        label: 'Tarih Bilgisi',
        sortable: false
      },
    ]
  }),
  computed: {
    ...mapGetters("stockV2", ["_stocks"]),
    ...mapGetters("users", ["_users"]),
    ...mapGetters("supplier", ["_suppliers"])
  },
  methods: {
    userName(id) {
      let user = this._users.find(user => user.id == id);
      if (!user) return '';
      return user.short_name;
    },
    supplierName(id) {
      let supplier = this._suppliers.find(supplier => supplier.id == id);
      if (!supplier) return ''
      return supplier.name
    },
    selectStock(stockCard) {
      this.selectedStock = stockCard;
    },
    resetStock(stock) {
      this.$swal({
        title: 'Sıfırlamak istediğinizden emin misiniz?',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        confirmButtonText: 'Sıfırla',
        preConfirm: () => {
          let data = {
            in_out: '-',
            market_price: stock.market_price,
            parent_id: stock.stock_card_id,
            price: stock.price,
            product_id: stock.product_id,
            discount: 0,
            quantity: stock.quantity,
            shelve_id: stock.shelve_id,
            shelve_name: stock.shelve_name,
            status: 1,
            stock_return: stock.stock_return,
            supplier_id: stock.supplier_id,
            vat_free_price: 0,
            message: 'Sıfırlama yapıldı'
          }
          return this.$store.dispatch('stock/addnew', data)
              .then(res => {
                return res;
              })
              .catch(error => {
                this.$swal.showValidationMessage(`Request failed:  ${error}`)
              })
        }
      });
    },
    decrease(stock) {
      this.$swal({
        title: 'Çıkartılacak Adet Belirt',
        html: '<input id="quantity" type="number" class="swal2-input" placeholder="Adet">' +
            '<input id="message" type="text" class="swal2-input" placeholder="Sebep belirtiniz">',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        confirmButtonText: 'Azalt',
        buttonsStyling: false,
        preConfirm: () => {
          let quantity = document.getElementById('quantity').value
          let message = document.getElementById('message').value
          if (!quantity || !message) return null;
          let data = {
            in_out: '-',
            market_price: stock.market_price,
            parent_id: stock.stock_card_id,
            price: stock.price,
            product_id: stock.product_id,
            discount: 0,
            quantity,
            shelve_id: stock.shelve_id,
            shelve_name: stock.shelve_name,
            status: 1,
            stock_return: stock.stock_return,
            supplier_id: stock.supplier_id,
            vat_free_price: 0,
            message
          }
          return this.$store.dispatch('stock/addnew', data)
              .then(res => {
                return res;
              })
              .catch(error => {
                this.$swal.showValidationMessage(`Request failed:  ${error}`)
              })
        }
      })
    }
  }
}
</script>

<style scoped>
</style>
